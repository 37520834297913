// SpaceBackground.js
import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import Stars from '../Stars';
import PlanetCoordinateCalculator from '../PlanetCoordinateCalculator';
import { OrbitControls } from 'three/examples/jsm/Addons.js';
import PlanetManager from '../PlanetManager';
import addLights from '../AddLights';
import { CSS3DRenderer } from 'three/addons/renderers/CSS3DRenderer.js';
import ContentHandler from '../ContentHandler';
import StartScreen from '../StartScreen';
import gsap,{Power1} from 'gsap';
import { RGBELoader } from 'three/examples/jsm/Addons.js';
import "./styles.scss"

const TopLevel = () => {

  var  renderer, planetManager, cssRenderer;

  const containerRef = useRef(null);

  const camera = useRef()
  const controls = useRef()
  const scene = useRef()

  const currentState = useRef("far")
  const stars = useRef()

  const entered = useRef(false)

  const [showPopup,setShowPopup] = useState(false)
  const [loading,setLoading] = useState(true)

  useEffect(() => {
    init()
    animate();
    window.addEventListener('resize', handleResize);
    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      containerRef.current.removeChild(renderer.domElement);
    };
  }, []);

 

  const init = async () => {
    // Set up the scene.current
    scene.current = new THREE.Scene();
    scene.current.background = new THREE.Color(0x0b101a)

    // Set up the camera.current
    camera.current = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, 0.1, 10000);
    camera.current.position.z = 500;

    // Set up the renderer
    renderer = new THREE.WebGLRenderer({antialias:true});
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.domElement.className = "canvas"
    renderer.physicallyCorrectLights = true;
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    renderer.setPixelRatio(window.devicePixelRatio);
    containerRef.current.appendChild(renderer.domElement);


var loadingManager = new THREE.LoadingManager()
var textureLoader = new THREE.TextureLoader(loadingManager)

cssRenderer = new CSS3DRenderer()
cssRenderer.setSize(window.innerWidth, window.innerHeight);
cssRenderer.domElement.className = "canvas"
containerRef.current.appendChild(cssRenderer.domElement);

loadingManager.onLoad = (()=>{
  setLoading(false)
  containerRef.current.style.opacity = 1
})


        
    addLights(scene.current)

    stars.current = new Stars(scene.current,textureLoader)

    controls.current = new OrbitControls(camera.current, cssRenderer.domElement)
  controls.current.enabled = false
    setTimeout(() => {
      var planetCoordinateCalculator = new PlanetCoordinateCalculator(scene.current, camera.current)
      var smallPlanets = planetCoordinateCalculator.getPlanets()
      var planetTexts = planetCoordinateCalculator.getTexts()
      var arrows = planetCoordinateCalculator.getArrows()

      planetManager= new PlanetManager(scene.current, planetCoordinateCalculator,textureLoader)

      planetManager.addPlanetsToScene(smallPlanets)
      planetManager.addTextsToScene(planetTexts)
      planetManager.addArrowsToScene(arrows)

      camera.current.position.z = -100;
      controls.current.target.z = -101;

    }, 50);

  }


  const moveCameraToPlanet=(planet)=>{
    currentState.current = "near"
     // Get the planet object from the scene.current
     const planetObject = scene.current.getObjectByName(planet.name);
    
     // Check if planetObject exists
     if (!planetObject) {
         console.error('Planet not found in the scene');
         return;
     }
     
     // Calculate the target position for the camera.current
     // Adjust the distance as needed
     const distance = planetObject.scale.x * 3; // Scale factor for distance
     const targetPosition = planetObject.position.clone().add(new THREE.Vector3(0, 0, distance));
     // Smoothly move the camera.current to the target position
     gsap.to(camera.current.position, {
         x: targetPosition.x,
         y: targetPosition.y,
         z: targetPosition.z,
         duration: 2,
         ease:Power1.easeInOut,
         onComplete:()=>{
          setShowPopup(true)
         }
     });

     gsap.to(controls.current.target,{
      x:planetObject.position.x,
      y:planetObject.position.y,
      z:planetObject.position.z,
      duration:1,
      ease:Power1.easeInOut
     })
  }



  function goBack(){
    gsap.to(camera.current.position, {
      x: 0,
      y: 0,
      z: 500,
      duration: 2,
      ease:Power1.easeInOut
  });

  gsap.to(controls.current.target,{
   x:0,
   y:0,
   z:0,
   duration:2,
   ease:Power1.easeInOut,
   onComplete:()=>{
        currentState.current = "far"
   }
  })

  setShowPopup(false)
  }



  // Animation loop
  const animate = () => {
    requestAnimationFrame(animate);
    if(stars.current){
    stars.current.stars.rotation.z += 0.0002;

    stars.current.sphere.rotation.y += 0.0005;
    stars.current.sphere.rotation.x += 0.0002;
    }

    controls.current.update()
    if(planetManager){
    planetManager.rotatePlanets()
    }
    renderer.render(scene.current, camera.current);
    cssRenderer.render(scene.current,camera.current)
  };

  // Handle window resize
  const handleResize = () => {
    console.log(currentState.current)
    camera.current.aspect = window.innerWidth / window.innerHeight;
    camera.current.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    cssRenderer.setSize(window.innerWidth, window.innerHeight);
    if(currentState.current == "far"){
    planetManager.updatePlanets()
    }
  };

  const enterScene=()=>{
    gsap.to(camera.current.position,{z:500,duration:2,onComplete:()=>{
      entered.current = true
    }})
    gsap.to(stars.current.sphere.position,{z:0,duration:2})
  }

  return (
  <>
  <ContentHandler moveCameraToPlanet={moveCameraToPlanet} goBack={goBack} showPopup={showPopup}/>
  <StartScreen enterScene={enterScene} loading={loading}/>
  <div ref={containerRef} className='canvas-container'/>
  </>
  );
};

export default TopLevel;
