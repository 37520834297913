import React, { useState } from "react";
import gsap from "gsap";
import "./styles.scss"
import Developing510PDF from '../../PDFs/White Paper_Developing a 510(k)_2.2018.PDF';
import CircularPDF from "../../PDFs/White Paper_EP Reprocessing and Circular Economy_7.2021.PDF"
import BeyondCostPDF from "../../PDFs/White Paper_Reprocessing Beyond Savings White Paper_7.2022.PDF"
import ReprocessingByNumbersPDF from "../../PDFs/White Paper_EP Reprocessing by the Numbers_6.2020.PDF"



function insertBreaks(text) {
    const parts = text.split(' ');
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && <br />}
      </React.Fragment>
    ));
  }


function CollectionHover({v}){

    function onMouseEnter(){
        gsap.fromTo(".collection-hover-content",{opacity:0},{pointerEvents:"all",opacity:1,duration:0.3})
    }

    function onMouseOut(){
        gsap.fromTo(".collection-hover-content",{opacity:1},{pointerEvents:"none",opacity:0,duration:0.3})
    }

    return(
        <div className={`planet-text-${v.type}`} id={v.id} key={v.name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseOut}>
        <span id={v.id}>{v.name}</span>
       <div className="collection-hover-content">
            <img src={`Assets/TopLevelHover/collection.png`} className="collection-hover-image"/>
            <div className="collection-hover-desc">
            <div className="collection-hover-heading">COLLECTION</div>
            <div className="collection-hover-text-content">Tailored collection system to maximize yield and avoid disruption of work routines. Professional collection techs collect based on utilization.</div>
            </div>
        </div>
    </div> 
    )
}


function PurchasingHover({v}){

    function onMouseEnter(){
        gsap.fromTo(".purchasing-hover-content",{opacity:0},{pointerEvents:"all",opacity:1,duration:0.3})
    }

    function onMouseOut(){
        gsap.fromTo(".purchasing-hover-content",{opacity:1},{pointerEvents:"none",opacity:0,duration:0.3})
    }

    return(
        <div className={`planet-text-${v.type}`} id={v.id} key={v.name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseOut}>
        <span id={v.id}>{v.name}</span>
       <div className="purchasing-hover-content">
            <img src={`Assets/TopLevelHover/purchasing.png`} className="purchasing-hover-image"/>
            <div className="purchasing-hover-desc">
            <div className="purchasing-hover-heading">PURCHASING</div>
            <div className="purchasing-hover-text-content">Concierge customer service help labs maximize savings. The hospital owns the device and determines how to use it.</div>
            </div>
        </div>
    </div> 
    )
}



function UtilizationHover({v}){

    function onMouseEnter(){
        gsap.fromTo(".utilization-hover-content",{opacity:0},{pointerEvents:"all",opacity:1,duration:0.3})
    }

    function onMouseOut(){
        gsap.fromTo(".utilization-hover-content",{opacity:1},{pointerEvents:"none",opacity:0,duration:0.3})
    }

    function openPDF(){
window.open(Developing510PDF)
    }

    return(
        <div className={`planet-text-${v.type}`} id={v.id} key={v.name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseOut}>
        <span id={v.id}>{v.name}</span>
       <div className="utilization-hover-content">
            <img src={`Assets/TopLevelHover/utilization.png`} className="utilization-hover-image"/>
            <div className="utilization-hover-desc">
            <div className="utilization-hover-heading">UTILIZATION</div>
            <div className="utilization-hover-text-content">Devices are substantially equivalent to new devices and there is no added patient risk. Lower complaint rates than original manufacturers.</div>
            <div className="utilization-button" onMouseDown={openPDF}>DOWNLOAD 510(k)<br/> WHITEPAPER</div>
            </div>
        </div>
    </div> 
    )
}


function ReprocessingHover({v,openVideoModal}){

    function onMouseEnter(){
        gsap.fromTo(".reprocessing-hover-content",{opacity:0},{pointerEvents:"all",opacity:1,duration:0.3})
    }

    function onMouseOut(){
        gsap.fromTo(".reprocessing-hover-content",{opacity:1},{pointerEvents:"none",opacity:0,duration:0.3})
    }

    return(
        <div className={`planet-text-${v.type}`} id={v.id} key={v.name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseOut}>
        <span id={v.id}>{v.name}</span>
       <div className="reprocessing-hover-content">
            <img src={`Assets/TopLevelHover/reprocessing.png`} className="reprocessing-hover-image"/>
            <div className="reprocessing-hover-desc">
            <div className="reprocessing-hover-heading">REPROCESSING</div>
            <div className="reprocessing-hover-text-content">Devices are inspected and reprocessed under stringent FDA oversight.<br/> Every single device is tested before being released for use.</div>
            <div className="reprocessing-button" onClick={()=>openVideoModal("Assets/Videos/Electrophysiology Reprocessing Technology.mp4")}>WATCH REPROCESSING VIDEO</div>
            </div>
        </div>
    </div> 
    )
}


function WasteHover({v}){

    function onMouseEnter(){
        gsap.fromTo(".waste-hover-content",{opacity:0},{pointerEvents:"all",opacity:1,duration:0.3})
    }

    function onMouseOut(){
        gsap.fromTo(".waste-hover-content",{opacity:1},{pointerEvents:"none",opacity:0,duration:0.3})
    }

    function openPDF(){
window.open(CircularPDF)
    }

    return(
        <div className={`top-level-text`} id={v.id} key={v.name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseOut}>
        <span id={v.id}>{insertBreaks(v.name)}</span>
       <div className="waste-hover-content">
            <img src={`Assets/TopLevelHover/waste.png`} className="waste-hover-image"/>
            <div className="waste-hover-desc">
            <div className="waste-hover-heading">LESS WASTE</div>
            <div className="waste-hover-text-content">An electrophysiology device weighs on average 0.324 lbs. A typical lab can reduce its waste by more than 400 lbs per year.</div>
            <div className="waste-button" onMouseDown={openPDF}>DOWNLOAD CIRCULAR<br/>ECONOMY WHITEPAPER</div>
            </div>
        </div>
    </div> 
    )
}



function EmissionsHover({v}){

    function onMouseEnter(){
        gsap.fromTo(".emissions-hover-content",{opacity:0},{pointerEvents:"all",opacity:1,duration:0.3})
    }

    function onMouseOut(){
        gsap.fromTo(".emissions-hover-content",{opacity:1},{pointerEvents:"none",opacity:0,duration:0.3})
    }

    function openPDF(){
        window.open(CircularPDF)
            }

    return(
        <div className={`top-level-text`} id={v.id} key={v.name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseOut}>
          <span id={v.id}>{insertBreaks(v.name)}</span>
       <div className="emissions-hover-content">
            <img src={`Assets/TopLevelHover/emissions.png`} className="emissions-hover-image"/>
            <div className="emissions-hover-desc">
            <div className="emissions-hover-heading">LOWER EMISSIONS</div>
            <div className="emissions-hover-text-content">The lab's carbon footprint is reduced by 1.94 lbs per device. A typical lab can reduce its carbon footprint by more than 2,500 2,250 lbs per year.</div>
            <div className="emissions-button" onMouseDown={openPDF}>DOWNLOAD CIRCULAR<br/>ECONOMY WHITEPAPER</div>
            </div>
        </div>
    </div> 
    )
}


function ReliableHover({v}){

    function onMouseEnter(){
        gsap.fromTo(".reliable-hover-content",{opacity:0},{pointerEvents:"all",opacity:1,duration:0.3})
    }

    function onMouseOut(){
        gsap.fromTo(".reliable-hover-content",{opacity:1},{pointerEvents:"none",opacity:0,duration:0.3})
    }

    function openPDF(){
        window.open(BeyondCostPDF)
            }

    return(
        <div className={`top-level-text`} id={v.id} key={v.name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseOut}>
         <span id={v.id}>{insertBreaks(v.name)}</span>
       <div className="reliable-hover-content">
            <img src={`Assets/TopLevelHover/reliable.png`} className="reliable-hover-image"/>
            <div className="reliable-hover-desc">
            <div className="reliable-hover-heading">RELIABLE SUPPLIES</div>
            <div className="reliable-hover-text-content">Using a reprocessed device is a short-term backorder solution. 2% of the procedures in a typical lab are cancelled due to backorders.</div>
            <div className="reliable-button" onMouseDown={openPDF}>DOWNLOAD BEYOND COST<br/>SAVINGS WHITEPAPER</div>
            </div>
        </div>
    </div> 
    )
}


function CostsHover({v}){

    function onMouseEnter(){
        gsap.fromTo(".cost-hover-content",{opacity:0},{pointerEvents:"all",opacity:1,duration:0.3})
    }

    function onMouseOut(){
        gsap.fromTo(".cost-hover-content",{opacity:1},{pointerEvents:"none",opacity:0,duration:0.3})
    }

    function openPDF(){
window.open(ReprocessingByNumbersPDF)
    }

    return(
        <div className={`top-level-text`} id={v.id} key={v.name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseOut}>
        <span id={v.id}>{insertBreaks(v.name)}</span>
       <div className="cost-hover-content">
            <img src={`Assets/TopLevelHover/cost.png`} className="cost-hover-image"/>
            <div className="cost-hover-desc">
            <div className="cost-hover-heading">LOWER COSTS</div>
            <div className="cost-hover-text-content">A reprocessed device costs approximately 50% of a new device. $1,200 savings for ultrasound catheters.<br/>A typical lab can save more than $500,000 per year.</div>
            <div className="cost-button" onMouseDown={openPDF}>DOWNLOAD REPROCESSING<br/>BY THE NUMBERS WHITEPAPER</div>
            </div>
        </div>
    </div> 
    )
}

export {CollectionHover, PurchasingHover,UtilizationHover,ReprocessingHover,WasteHover, EmissionsHover, ReliableHover, CostsHover}