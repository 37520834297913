import React from 'react'
import "./styles.scss"
import HospitalLeadersPDF from "../../../PDFs/White Paper_Hospital Leaders_4.2018_v1.0.PDF"

function EPProcedures(){

    function openPDF(){
        window.open(HospitalLeadersPDF)
    }
    
    return(
        <div className='ep-container'>
            <div className='ep-content'>
                <div className='ep-procedure'>
                <img src="Assets/PagesContent/EPProcedures/ep-chart.png" className='ep-chart'/>
                <div className='right-ep-heading'>Electrophysiology<br/>Procedures
                <img src="Assets/PagesContent/EPProcedures/right-arrow.svg" className='right-ep-arrow'/>
                </div>
                </div>

                <div className='ep-text-boxes'>
                    <div className='ep-text-box'>Most catheters in an AF ablation<br/>procedures can be reprocessed –<br/>and save the lab more than $3,000<br/>per procedure.
                    <img src='Assets/PagesContent/EPProcedures/ep-graph.png' alt='Hover Image 2' className='ep-hover-image ep-hover-image-1'/>
                    </div>
                    <div className='ep-text-box'>Reprocessing can bring down the<br/>medical device percentage of<br/>reimbursement from 45% to 31%
                    <div className='ep-hover-div'>
                    <img src="Assets/PagesContent/EPProcedures/ep-table.png" className="ep-hover-div-image"/>
                    <div className='text-box-action'>
                        <img src='Assets/PagesContent/EPProcedures/ep-action-image.png' className='ep-action-text-box-image'/>
                        <div className='ep-text-box-action'>Using reprocessed single-use<br/>devices helps reduce costs.<br/>However, opportunities to<br/>save in cardiology are higher<br/>than anywhere else in the<br/>hospital.</div>
                        <div className='ep-pdf-button' onClick={openPDF}>DOWNLOAD HOSPITAL<br/>LEADERS WHITEPAPER</div>
                    </div>
                </div>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EPProcedures
