import React, { useState } from "react";
import { topLevel } from "../Data";
import "./styles.scss"
import { CollectionHover, PurchasingHover, UtilizationHover, ReprocessingHover, WasteHover, EmissionsHover, ReliableHover, CostsHover } from '../HoverContents'
import VideoModal from "../VideoModal";
import ContentPopup from "../ContentPopup";


function insertBreaks(text) {
    const parts = text.split('()');
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && <br />}
      </React.Fragment>
    ));
  }

function ContentHandler({moveCameraToPlanet,goBack,showPopup}) {

    const [showVideo, setShowVideo] = useState(false)
    const [videoFile, setVideoFile] = useState("")
    const [currentPage,setCurrentPage] = useState({})

    function openVideoModal(video) {
        setVideoFile(video)
        setShowVideo(true)
    }

    function closeVideoModal() {
        setShowVideo(false)
        setVideoFile("")
    }

    function handleClick(v){
        console.log(v)
        setCurrentPage(v)
        moveCameraToPlanet(v)
    }

   

    return (
        <div className="content-handler">
            <div>
                {topLevel.planets.map((v) => {
                    return (
                        <div key={v.id}>{v.action=="hover" ? (
                            <>
                                {v.id == "collection" && <CollectionHover v={v} />}
                                {v.id == "purchasing" && <PurchasingHover v={v} />}
                                {v.id == "utilization" && <UtilizationHover v={v} />}
                                {v.id == "reprocessing" && <ReprocessingHover v={v} openVideoModal={openVideoModal} />}
                            </>
                        )
                            :
                            <div className={`planet-text-${v.type}`} id={v.id} key={v.name} onPointerDown={()=>handleClick(v)}>
                                <span id={v.id}>{insertBreaks(v.name)}</span>
                            </div>
                        }</div>
                    )
                })}

            </div>


            <div>
                {topLevel.topLevelTexts.map((v) => {
                    return (
                        <div key={v.id}>
                              {v.id == "waste" && <WasteHover v={v} />}
                                    {v.id == "emissions" && <EmissionsHover v={v}/>}
                                    {v.id == "reliable" && <ReliableHover v={v}/>}
                                    {v.id == "costs" && <CostsHover v={v}/>}
                        </div>
                    )
                })}
            </div>

            {showVideo && <VideoModal videoFile={videoFile} closeVideoModal={closeVideoModal} />}
            {showPopup && <ContentPopup goBack={goBack} currentPage={currentPage} openVideoModal={openVideoModal}/>}
        </div>
    )
}


export default ContentHandler