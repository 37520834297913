import React from "react";
import "./styles.scss"
import Environment from "../PageContents/Environment";
import ClinicalIntegration from "../PageContents/ClinicalIntegration";
import EPProcedures from "../PageContents/EPProcedures";
import ReprocessingTechnology from "../PageContents/ReprocessingTechnology";
import BioScience from "../PageContents/BioScience";
import ResearchDevelopment from "../PageContents/ResearchDevelopment";
import ReuseTechnology from "../PageContents/ReuseTechnology";
import NewProduct from "../PageContents/NewProduct";

function ContentPopup({goBack,currentPage,openVideoModal }){

    console.log(currentPage)

    return(
        <div className="content-popup">
            <div className="back-button" onClick={goBack}>Back</div>
            <div className="content">
                {currentPage.id=="environment" && <Environment/>}
                {currentPage.id=="clinical" && <ClinicalIntegration openVideoModal={openVideoModal}/>}
                {currentPage.id=="electrophysiology" && <EPProcedures/>}
                {currentPage.id=="reprocessingtech" && <ReprocessingTechnology openVideoModal={openVideoModal}/>}
                {currentPage.id=="bioscience" && <BioScience openVideoModal={openVideoModal}/>}
                {currentPage.id=="research" && <ResearchDevelopment openVideoModal={openVideoModal}/>}
                {currentPage.id=="reuse" && <ReuseTechnology openVideoModal={openVideoModal}/>}
                {currentPage.id=="product" && <NewProduct openVideoModal={openVideoModal}/>}
            </div>
        </div>
    )
}

export default ContentPopup