import React from "react";
import "./styles.scss"

function ResearchDevelopment({openVideoModal}){
    return(
        <div className="research-container">
            <div className="research-content">
                <img src="Assets/PagesContent/ResearchDevelopment/research-image.png" className="research-image"/>
                <div className="research-text-box">
                <span>Research and development<br/>fosters new, advanced<br/>reprocessing technology.</span>
                <div className='research-hover-div'>
                    <img src="Assets/PagesContent/ResearchDevelopment/video-thumbnail.png" onClick={()=>openVideoModal("Assets/Videos/Innovative Health Research & Innovation Center.mp4")} className="research-hover-div-image"/>
                    <div className="research-watch-video-button" onClick={()=>openVideoModal("Assets/Videos/Innovative Health Research & Innovation Center.mp4")}>WATCH RESEARCH AND<br/> INNOVATION CENTER VIDEO</div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ResearchDevelopment