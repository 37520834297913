import React from "react";
import "./styles.scss"

function VideoModal({videoFile,closeVideoModal}){
return(
    <div className="video-modal">
        <video src={videoFile} controls/>
        <span className="cross-button" onClick={closeVideoModal}>×</span>
    </div>
)
}

export default VideoModal