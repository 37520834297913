import * as THREE from 'three'
import { topLevel } from '../Data'

class PlanetCoordinateCalculator{
    constructor(scene,camera){

        var planeGeometry = new THREE.PlaneGeometry(2000,2000)
        var planeMaterial = new THREE.MeshBasicMaterial({color:0xff0000,transparent:true,opacity:0.2,side: THREE.DoubleSide})
        this.plane = new THREE.Mesh(planeGeometry,planeMaterial)

        this.raycaster = new THREE.Raycaster()
        this.camera = camera

        this.pointer = new THREE.Vector2()
    }




    getPlanets(){
        var planets = []
        topLevel.planets.map((v)=>{
            var clientX = window.innerWidth*v.coordinate.x
            var clientY = window.innerHeight*v.coordinate.y

            this.pointer.x = ( clientX / window.innerWidth ) * 2 - 1;
            this.pointer.y = - ( clientY / window.innerHeight ) * 2 + 1;
            
            this.raycaster.setFromCamera( this.pointer, this.camera );
            const intersects = this.raycaster.intersectObject( this.plane );
            if(intersects[0]){
                planets.push({type:v.type,name:v.name,point:intersects[0].point,color:v.color,id:v.id})
            }
        })
        return planets
    }

    getTexts(){
        var texts = []
        topLevel.topLevelTexts.map((v)=>{
            var clientX = window.innerWidth*v.coordinate.x
            var clientY = window.innerHeight*v.coordinate.y

            this.pointer.x = ( clientX / window.innerWidth ) * 2 - 1;
            this.pointer.y = - ( clientY / window.innerHeight ) * 2 + 1;
            
            this.raycaster.setFromCamera( this.pointer, this.camera );
            const intersects = this.raycaster.intersectObject( this.plane );
            if(intersects[0]){
                texts.push({name:v.name,point:intersects[0].point,id:v.id})
            }
        })
        return texts
    }

    getArrows(){
        var arrows = []
        topLevel.topLevelArrows.map((v)=>{
            var clientX = window.innerWidth*v.coordinate.x
            var clientY = window.innerHeight*v.coordinate.y

            this.pointer.x = ( clientX / window.innerWidth ) * 2 - 1;
            this.pointer.y = - ( clientY / window.innerHeight ) * 2 + 1;
            
            this.raycaster.setFromCamera( this.pointer, this.camera );
            const intersects = this.raycaster.intersectObject( this.plane );
            if(intersects[0]){
                arrows.push({point:intersects[0].point,rotation:v.rotation,type:v.type})
            } 
        })
        return arrows
    }
}




export default PlanetCoordinateCalculator