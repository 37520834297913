import React from "react";
import "./styles.scss";
import gsap from "gsap";

function StartScreen({ enterScene, loading }) {
  function hideStartScreen() {
    enterScene();
    gsap.to(".start-screen-root", {
      opacity: 0,
      duration: 1,
      onComplete: () => {
        gsap.to(".start-screen-root", { display: "none" });
      },
    });
  }

  return (
    <div className="start-screen-root">
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="start-subheading">Single-Use Device Reprocessing</div>
          <div className="start-heading">A MAP OF THE UNIVERSE</div>
          <div className="start-instructions">
            CLICK BELOW TO ENTER
            <br />
            <div className="start-instructions-2">
            HOVER OVER AND CLICK OBJECTS TO<br/> ACCESS ADDITIONAL CONTENT
            </div>
          </div>
          <div className="enter-button" onClick={hideStartScreen}>
            ENTER
          </div>
        </>
      )}
    </div>
  );
}

export default StartScreen;
