import * as THREE from 'three'

function addLights(scene){
// Ambient Light: Soft overall illumination to simulate scattered light in space
const ambientLight = new THREE.AmbientLight(0x404040, 70); // Dim white light
scene.add(ambientLight);

// Directional Light: Simulates the main light source (like a distant star or sun)
const directionalLight = new THREE.DirectionalLight(0xffffff, 20);
directionalLight.position.set(10, 10, -10); // Position it to one side
directionalLight.castShadow = true;
scene.add(directionalLight);

// Point Light: Additional light to enhance the lighting on specific planets or create star-like effects
const pointLight = new THREE.PointLight(0xffcc66, 1.5, 150);
pointLight.position.set(-20, 15, 5);
scene.add(pointLight);

// Hemisphere Light: Adds a subtle glow effect to simulate cosmic background light
const hemisphereLight = new THREE.HemisphereLight(0x444444, 0x000000, 0.5); // Soft light from above
scene.add(hemisphereLight);
}

export default addLights