const topLevel = {
    planets: [
        {
            name: "Collection",
            coordinate: { x: 0.5, y: 0.33 },
            color: 0x8bb3f0,
            type: "big",
            id: "collection",
            action: "hover"
        },
        {
            name: "Utilization",
            coordinate: { x: 0.415, y: 0.5 },
            color: 0x8bb3f0,
            type: "big",
            id: "utilization",
            action: "hover"
        },
        {
            name: "Reprocessing",
            coordinate: { x: 0.585, y: 0.5 },
            color: 0x8bb3f0,
            type: "big",
            id: "reprocessing",
            action: "hover"
        },
        {
            name: "Purchasing",
            coordinate: { x: 0.5, y: 0.67 },
            color: 0x8bb3f0,
            type: "big",
            id: "purchasing",
            action: "hover"
        },
        {
            name: "Environment",
            coordinate: { x: 0.5, y: 0.08 },
            color: 0xfce1d6,
            type: "medium",
            id: "environment",
            action: "click"
        },
        {
            name: "Reuse()Technology",
            coordinate: { x: 0.75, y: 0.5 },
            color: 0xc89b9c,
            type: "medium",
            id: "reuse",
            action: "click"
        },
        {
            name: "New Product",
            coordinate: { x: 0.5, y: 0.88 },
            color: 0xe392f7,
            type: "medium",
            id: "product",
            action: "click"
        },
        {
            name: "Electrophysiology()Procedures",
            coordinate: { x: 0.25, y: 0.5 },
            color: 0xa2e39e,
            type: "medium",
            id: "electrophysiology",
            action: "click"
        },
        {
            name: "Reprocessing Technology",
            coordinate: { x: 0.15, y: 0.1 },
            color: 0xb8c9fb,
            type: "small",
            id: "reprocessingtech",
            action: "click"
        },
        {
            name: "Clinical Integration",
            coordinate: { x: 0.85, y: 0.1 },
            color: 0xacfcc8,
            type: "small",
            id: "clinical",
            action: "click"
        },
        {
            name: "Bioscience",
            coordinate: { x: 0.15, y: 0.85 },
            color: 0xc37bf9,
            type: "small",
            id: "bioscience",
            action: "click"
        },
        {
            name: "Research & Development",
            coordinate: { x: 0.85, y: 0.85 },
            color: 0x75bcce,
            type: "small",
            id: "research",
            action: "click"
        }
    ],
    topLevelTexts: [
        {
            name: "Less Waste",
            coordinate: { x: 0.35, y: 0.3 },
            id:"waste"
        },
        {
            name: "Lower Emissions",
            coordinate: { x: 0.65, y: 0.3 },
            id:"emissions"
        },
        {
            name: "Lower Costs",
            coordinate: { x: 0.35, y: 0.7 },
            id:"costs"
        },
        {
            name: "Reliable Supplies",
            coordinate: { x: 0.65, y: 0.7 },
            id:"reliable"
        }
    ],
    topLevelArrows:[
        {
            coordinate: { x: 0.33, y: 0.50 },
            rotation:-Math.PI/2,
            type:"straight"
        },
        {
            coordinate: { x: 0.67, y: 0.5 },
            rotation:Math.PI/2,
            type:"straight"
        },
        {
            coordinate: { x: 0.5, y: 0.21 },
            rotation:0,
            type:"straight"
        },
        {
            coordinate: { x: 0.5, y: 0.79 },
            rotation:0,
            type:"straight"
        },
        {
            coordinate: { x: 0.455, y: 0.41 },
            rotation:Math.PI/2,
            type:"rounded"
        },
        {
            coordinate: { x: 0.545, y: 0.41 },
            rotation:0,
            type:"rounded"
        },
        {
            coordinate: { x: 0.545, y: 0.59 },
            rotation:-Math.PI/2,
            type:"rounded"
        },
        {
            coordinate: { x: 0.455, y: 0.59 },
            rotation:Math.PI,
            type:"rounded"
        }

    ]
}

export { topLevel }