import React from "react";
import "./styles.scss"

function BioScience({openVideoModal}){
    return(
        <div className="bioscience-container">
            <div className="bioscience-content">
                <img src="Assets/PagesContent/Bioscience/bioscience-image.png" className="bioscience-image"/>
                <div className="bioscience-text-box">
                <span>Advanced bioscience<br/>competencies help the<br/>reprocessor bring savings to the customer – safer and faster.</span>
                <div className='bioscience-hover-div'>
                    <img src="Assets/PagesContent/Bioscience/video-thumbnail.png" onClick={()=>openVideoModal("Assets/Videos/Innovative Health - Bioscience Laboratory.mp4")} className="bioscience-hover-div-image"/>
                    <div className="bioscience-watch-video-button" onClick={()=>openVideoModal("Assets/Videos/Innovative Health - Bioscience Laboratory.mp4")}>WATCH BIOSCIENCE<br/> LABORATORY VIDEO</div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default BioScience