import React from "react";
import "./styles.scss"

function ClinicalIntegration({openVideoModal}){
    return(
        <div className="clinical-container">
            <div className="clinical-content">
                <img src="Assets/PagesContent/ClinicalIntegration/clinical-image.png" className="clinical-image"/>
                <div className="clinical-text-box">
                <span>Clinical Integration drives<br/>program compliance, physician<br/>support and higher savings.</span>
                <div className='clinical-hover-div'>
                    <img src="Assets/PagesContent/ClinicalIntegration/video-thumbnail.png" onClick={()=>openVideoModal("Assets/Videos/Innovative Health - Clinical Integration.mp4")} className="clinical-hover-div-image"/>
                    <div className="clinical-watch-video-button" onClick={()=>openVideoModal("Assets/Videos/Innovative Health - Clinical Integration.mp4")}>WATCH CLINICAL INTEGRATION VIDEO</div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ClinicalIntegration