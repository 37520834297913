import * as THREE from 'three'

class Stars{
    constructor(scene,textureLoader){

      const starTexture = textureLoader.load(
        "textures/spark1.png"
      );

        // Create a star field
    this.starsGeometry = new THREE.BufferGeometry();
    this.starsMaterial = new THREE.PointsMaterial({ color: 0xffffff, size: 10,map:starTexture,transparent:true,sizeAttenuation:true,depthWrite:false});
    this.starVertices = [];

    for (let i = 0; i < 10000; i++) {
      const x = this.getRandomInRange(-500,500)
      const y = this.getRandomInRange(-500,500)
      const z = this.getRandomInRange(-100,-300)
      this.starVertices.push(x, y, z);
    }

    this.starsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(this.starVertices, 3));
    this.stars = new THREE.Points(this.starsGeometry, this.starsMaterial);
    scene.add(this.stars);


    var texture = textureLoader.load("textures/galaxy2.webp")
  
    var geometry = new THREE.SphereGeometry( 300,64,64 ); 
    const material = new THREE.MeshBasicMaterial( { map:texture,side:THREE.BackSide } ); 
    material.map.colorSpace = THREE.SRGBColorSpace
    this.sphere = new THREE.Mesh( geometry, material );
    this.sphere.position.set(0,0,-500)
    scene.add(this.sphere)

    }


    getRandomInRange(min, max) {
      return Math.random() * (max - min) + min;
  }
}

export default Stars