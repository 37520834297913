import React from "react";
import "./styles.scss"
import ReprocessingNumbersPDF from "../../../PDFs/White Paper_EP Reprocessing by the Numbers_6.2020.PDF"
import RestrictiveInnovationPDF from "../../../PDFs/White Paper_Restrictive Device Innovation_6.2018_v1.0.PDF"

function NewProduct({openVideoModal}){

    function openPDF(){
        window.open(ReprocessingNumbersPDF)
    }

    function openPDF2(){
        window.open(RestrictiveInnovationPDF)
    }

    return(
        <div className="new-container">
            <div className="new-manu-container">
            <div className="new-manu-hover">
                <div className="new-manu-hover-box">
                <img src="Assets/PagesContent/NewProduct/state.png" className="new-manu-hover-image" onClick={()=>{openVideoModal("Assets/Videos/Innovative Health - 2024 State of the Union.mp4")}}/>
                </div>
                    <div className="new-manu-hover-button" onClick={()=>{openVideoModal("Assets/Videos/Innovative Health - 2024 State of the Union.mp4")}}>WATCH 2024 STATE OF THE UNION<br/> WITH CEO RICK FERREIRA</div>
                </div>
                <img src="Assets/PagesContent/NewProduct/device.png" className="new-manu-image"/>
            </div>
            <div className="new-cost-container">
                <div className="new-cost-hover">
                    <div className="new-cost-hover-box-1">
                        <img src="Assets/PagesContent/NewProduct/sales-rep.png" className="new-cost-hover-box-img-1"/>
                        <div className="new-cost-hover-box-button-1" onClick={openPDF}>DOWNLOAD REPROCESSING <br/>BY THE NUMBERS WHITEPAPER</div>
                    </div>
                    <div className="new-cost-hover-box-2">
                        <img src="Assets/PagesContent/NewProduct/sales-rep-2.png" className="new-cost-hover-box-img-2"/>
                        <div className="new-cost-hover-box-button-2" onClick={openPDF2}>DOWNLOAD RESTRICTIVE DEVICE<br/> INNOVATION WHITEPAPER</div>
                    </div>
                </div>
            <img src="Assets/PagesContent/NewProduct/top-arrow.svg" className="new-top-arrow"/>
            NEW PRODUCT
            <img src="Assets/PagesContent/NewProduct/cost.png" className="new-cost-image"/>
            </div>
          
            <div className="new-hospital-container">
                    <img src="Assets/PagesContent/NewProduct/restrictive.png" className="new-hospital-hover"/>
            <img src="Assets/PagesContent/NewProduct/hospital-image.png" className="new-hospital-image"/>
            </div>
        </div>
    )
}

export default NewProduct