import React from "react";
import "./styles.scss"
import CablesPDF from '../../../PDFs/White Paper_The Technology of Cables Reprocessing_11.2023_v1.0.PDF';
import DevelopingPDF from "../../../PDFs/White Paper_Developing a 510(k)_2.2018.PDF"


function ReuseTechnology({openVideoModal}){

    function openPDF(){
        window.open(CablesPDF)
            }

            function openPDF2(){
window.open(DevelopingPDF)
            }

    return(
        <div className="reuse-container">
            <div className="reuse-boxes">
                <div className="reuse-box">
                    <div className="reuse-hover-div-container">
                <div className='reuse-hover-div'>
                    <img src="Assets/PagesContent/ReuseTechnology/microlumen.png" onClick={()=>openVideoModal("Assets/Videos/Microlumen Cleaning & Inspection.mp4")} className="reuse-hover-div-image"/>
                    <div className="reuse-watch-video-button" onClick={()=>openVideoModal("Assets/Videos/Microlumen Cleaning & Inspection.mp4")}>WATCH MICROLUMEN CLEANING<br/>AND INSPECTION VIDEO</div>
                </div>
                <div className='reuse-hover-div-full' style={{backgroundImage:"url(Assets/PagesContent/ReuseTechnology/cables.png)"}}>
                    <div className="reuse-watch-video-button" onClick={openPDF}>DOWNLOAD CABLES WHITEPAPER</div>
                </div>
                </div>
                    Innovative Health uses its<br/>reprocessing technology<br/>competencies to also reprocess<br/>reusable electrophysiology cable.</div>
                <div className="reuse-heading">
                    <img src="Assets/PagesContent/ReuseTechnology/left-arrow.svg" className="reuse-heading-image"/>
                    REUSE<br/>TECHNOLOGY
                </div>
                <div className="reuse-box">
                <div className='reuse-hover-div-2'>
                    <img src="Assets/PagesContent/ReuseTechnology/location-sensor.png" onClick={()=>openVideoModal("Assets/Videos/Location Sensor Testing Machine.mp4")} className="reuse-hover-div-image"/>
                    <div className="reuse-watch-video-button" onClick={()=>openVideoModal("Assets/Videos/Location Sensor Testing Machine.mp4")}>WATCH LOCATION SENSOR<br/>TESTING MACHINE VIDEO</div>
                </div>
                    Very advanced reprocessing<br/>technology has been developed<br/>in electrophysiology.
                    </div>
            </div>
            <div className="reuse-fda-section">
                <div className="reuse-fda-hover-div">
                <div className="reuse-fda-video-button" onClick={()=>openVideoModal("Assets/Videos/Innovative Health Research & Innovation Center.mp4")}>WATCH RESEARCH AND INNOVATION CENTER VIDEO</div>
                <div className="reuse-fda-video-button" onClick={()=>openVideoModal("Assets/Videos/Cleaning.mp4")}>WATCH CLEANING VIDEO</div>
                <div className="reuse-fda-video-button" onClick={()=>openVideoModal("Assets/Videos/Function Testing.mp4")}>WATCH FUNCTION TESTING VIDEO</div>
                <div className="reuse-fda-video-button" onClick={openPDF2}>DOWNLOAD 501(k) WHITEPAPER</div>
                </div>
                <img src="Assets/PagesContent/ReuseTechnology/fda-image.png" className="reuse-fda-image"/>
            </div>
            <div className="reuse-process-section">
            <div className="reuse-process-hover-div">
                <div className="reuse-process-video-button-1" onClick={()=>openVideoModal("Assets/Videos/Login.mp4")}>WATCH LOGIN VIDEO</div>
                <div className="reuse-process-video-button-2" onClick={()=>openVideoModal("Assets/Videos/Cleaning.mp4")}>WATCH CLEANING VIDEO</div>
                <div className="reuse-process-video-button-3" onClick={()=>openVideoModal("Assets/Videos/Testing.mp4")}>WATCH TESTING VIDEO</div>
                <div className="reuse-process-video-button-4" onClick={()=>openVideoModal("Assets/Videos/Inspection.mp4")}>WATCH INSPECTION VIDEO</div>
                </div>
                <img src="Assets/PagesContent/ReuseTechnology/process-image.png" className="reuse-process-image"/>
            </div>
        </div>
    )
}

export default ReuseTechnology