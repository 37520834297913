import * as THREE from 'three'
import { CSS3DObject } from 'three/examples/jsm/Addons.js'

class PlanetManager{
    constructor(scene,planetCoordinateCalculator,textureLoader){
        this.scene = scene
        this.planetCoordinateCalculator = planetCoordinateCalculator
        this.texture = textureLoader.load("textures/planet3.jpg")
        this.planets = []
        this.texts = []
        this.arrows = []
    }

    addPlanetsToScene(planets){
        planets.map((v,i)=>{
            var geometry;
            geometry = new THREE.SphereGeometry( 1, 64, 64 ); 
            const material = new THREE.MeshPhysicalMaterial( { map:this.texture,color:v.color } ); 
            const sphere = new THREE.Mesh( geometry, material );
            sphere.name = v.name
            sphere.userData.type = v.type
        
            if(v.type=="big"){
                sphere.scale.setScalar(18)
            }
            else if(v.type=="medium"){
                sphere.scale.setScalar(13)
            }
            else if(v.type=="small"){
                sphere.scale.setScalar(7)
            }
            this.planets.push(sphere)
            sphere.position.copy(v.point)
            this.scene.add( sphere );
        })
        this.addTextToPlanets(planets)
    }

    updatePlanets(){
        var planets = this.planetCoordinateCalculator.getPlanets()
        var texts = this.planetCoordinateCalculator.getTexts()
        var arrows = this.planetCoordinateCalculator.getArrows()
       
        planets.map((v)=>{
            var planet = this.scene.getObjectByName(v.name)
            var planetText = this.scene.getObjectByName(v.name+"text")
            planet.position.copy(v.point)
            planetText.position.copy(v.point)
        })

        texts.map((v)=>{
            var text = this.scene.getObjectByName(v.name+"text")
            text.position.copy(v.point)
        })

        arrows.map((v,i)=>{
            var text = this.scene.getObjectByName("arrow"+i)
            text.position.copy(v.point)
        })
    }

    rotatePlanets(){
        this.planets.map((v)=>{
            v.rotation.x+=0.004
            v.rotation.y+=0.004
        })
    }

    addTextToPlanets(planets){
        planets.map((v,i)=>{
            var element = document.getElementById(v.id)
            
            var cssObject = new CSS3DObject(element)
            cssObject.name = v.name+"text"
            cssObject.position.copy(v.point)
            if(v.type=="big"){
                cssObject.scale.setScalar(0.2)
                element.className = "planet-text-big"
                }
                else if(v.type=="medium"){
                    cssObject.scale.setScalar(0.25)
                    // cssObject.position.y = v.point.y - 18
                    element.className = "planet-text-medium"
                }
                else if(v.type=="small"){
                    cssObject.scale.setScalar(0.25)
                    // cssObject.position.y = v.point.y - 15
                    element.className = "planet-text-small"
                }
           
            this.scene.add(cssObject)
        })
    }

    addTextsToScene(texts){
        texts.map((v,i)=>{
            var element = document.getElementById(v.id)
            console.log(v.id)
             var cssObject = new CSS3DObject(element)
            cssObject.name = v.name+"text"
            cssObject.scale.setScalar(0.25)
            cssObject.position.copy(v.point)
            this.scene.add(cssObject)
            this.texts.push(v)
        })
    }


    addArrowsToScene(arrows){
        arrows.map((v,i)=>{
            var element = document.createElement("div")
            element.innerHTML = `<img src="Assets/${v.type=="straight" ? "arrow.svg" : "arrow-rounded.svg"}" class="arrow-img"/>`
            element.className = "top-arrow"
             var cssObject = new CSS3DObject(element)
               cssObject.name = "arrow"+i
               if(v.type=="straight"){
            cssObject.scale.setScalar(0.21)
               }
               else{
                cssObject.scale.setScalar(0.9)
               }
            cssObject.rotateZ(v.rotation)
            cssObject.position.copy(v.point)
            this.scene.add(cssObject)
            this.arrows.push(v)
        })
    }


}

export default PlanetManager