import React from "react";
import "./styles.scss"

function ReprocessingTechnology({openVideoModal}){
    return(
        <div className="reprocessing-container">
            <div className="reprocessing-content">
                <img src="Assets/PagesContent/Reprocessing/reprocessing-image.png" className="reprocessing-image"/>
                <div className="reprocessing-text-box">
                <span>Advanced reprocessing<br/>technology allows Innovative<br/>Health to clean and test very<br/>complex devices.</span>
                <div className="reprocessing-hover-divs">
                <div className='reprocessing-hover-div'>
                    <img src="Assets/PagesContent/Reprocessing/location-sensor.png" onClick={()=>openVideoModal("Assets/Videos/Location Sensor Testing Machine.mp4")} className="reprocessing-hover-div-image"/>
                    <div className="reprocessing-watch-video-button" onClick={()=>openVideoModal("Assets/Videos/Location Sensor Testing Machine.mp4")}>WATCH ANIMATED VIDEO:<br/>LOCATION SENSOR TESTING</div>
                </div>
                <div className='reprocessing-hover-div'>
                    <img src="Assets/PagesContent/Reprocessing/microlumen.png" onClick={()=>openVideoModal("Assets/Videos/Microlumen Cleaning & Inspection.mp4")} className="reprocessing-hover-div-image"/>
                    <div className="reprocessing-watch-video-button" onClick={()=>openVideoModal("Assets/Microlumen Cleaning & Inspection.mp4")}>WATCH ANIMATED VIDEO:<br/>MICROLUMEN CLEANING</div>
                </div>
                <div className='reprocessing-hover-div'>
                    <img src="Assets/PagesContent/Reprocessing/macrolumen.png" onClick={()=>openVideoModal("Assets/Videos/Macrolumen Cleaning & Inspection.mp4")} className="reprocessing-hover-div-image"/>
                    <div className="reprocessing-watch-video-button" onClick={()=>openVideoModal("Assets/Videos/Macrolumen Cleaning & Inspection.mp4")}>WATCH ANIMATED VIDEO:<br/>MACROLUMEN CLEANING</div>
                </div>
                <div className='reprocessing-hover-div'>
                    <img src="Assets/PagesContent/Reprocessing/hydrophilic.png" onClick={()=>openVideoModal("Assets/Videos/Hydrophilic Coating.mp4")} className="reprocessing-hover-div-image"/>
                    <div className="reprocessing-watch-video-button" onClick={()=>openVideoModal("Assets/Videos/Hydrophilic Coating.mp4")}>WATCH ANIMATED VIDEO:<br/>HYDROPHILIC COATING</div>
                </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ReprocessingTechnology