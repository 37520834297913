import React from 'react';
import './styles.scss';
import RecyclingPDF from "../../../PDFs/White Paper_Reusing, Recycling, and Reprocessing in Healthcare_4.2024_v0.1.PDF"

const Environment = () => {


    function openPDF(){
        window.open(RecyclingPDF)
            }

  return (
    <div className="container">
      <div className="header">
        <h1>Linear vs Circular Supply Solutions</h1>
        <p>Both reusable devices and reprocessable devices represent circular solutions to the linear solution of single-use devices</p>
      </div>

      <div className='text-boxes'>
        <div className='text-box'>
          <p>New products have a carbon<br/> emission footprint twice as high<br/> as a reprocessed device.</p>
          <img src='Assets/PagesContent/Environment/carbon-hover.png' alt='Hover Image 1' className='hover-image hover-image-1'/>
        </div>

        <div className='text-box'>
          <p>Most of a hospital's waste doesn't<br/> come from what they do.<br/> It comes from what they buy.</p>
          <img src='Assets/PagesContent/Environment/waste-hover.png' alt='Hover Image 2' className='hover-image hover-image-2'/>
        </div>
      </div>

      <img src='Assets/PagesContent/Environment/Flowchart.png' className='flowchart-image'/>

      <div className='text-boxes box-margin-top'>
        <div className='text-box'>
          <p>Recycling is a poor substitute<br/>for the use of reusable or<br/>reprocessed devices.</p>
          <div className='hover-div'>
          <img src='Assets/PagesContent/Environment/recycling-image.png' alt='Hover Image 3' className='hover-div-image'/>
          <div className='hover-div-button' onClick={openPDF}>DOWNLOAD RECYCLING WHITEPAPER</div>
          </div>
        </div>
      </div>

      <div className='bottom-heading'>
        ENVIRONMENT
        <img src='Assets/PagesContent/Environment/bottom-arrow.svg' className='bottom-heading-arrow'/>
      </div>

    </div>
  );
};

export default Environment;
